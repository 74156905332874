import { Routes } from '@angular/router';
const ɵ0 = () => import("./home/home.module.ngfactory").then((m) => m.HomePageModuleNgFactory), ɵ1 = () => import("./login/login.module.ngfactory").then((m) => m.LoginPageModuleNgFactory);
const routes = [
    {
        path: 'home',
        loadChildren: ɵ0,
    },
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
    },
    {
        path: 'login',
        loadChildren: ɵ1,
    },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1 };
